/**
 * talkitout theme custom JS main file
 */

// Initialize and add the map
function initMap() {
    console.log('initMap function is called');

    // Center the map over Bournemouth, UK
    var center = { lat: 50.7192, lng: -1.8808 };
    // Default zoom level
    var zoomLevel = 12;

    // Check screen width and adjust zoom level
    if (window.innerWidth <= 600) {
        zoomLevel = 10; // Adjust this value for mobile
    }
    // Create a map with specified options
    var map = new google.maps.Map(document.getElementById('map'), {
        center: center,
        zoom: zoomLevel, // Adjust the zoom level as needed
        disableDefaultUI: true, // Disable map controls
        styles: [
            {
                featureType: 'all',
                elementType: 'all',
                stylers: [
                    { lightness: '60' },
                    { saturation: '0' },
                    { visibility: 'simplified' }
                ]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    { color: '#a2daf2' }
                ]
            }
        ] // Add your custom map styles here
    });

    // Define map points
    var locations = [
        { title: 'Bournemouth', position: { lat: 50.7192, lng: -1.8808 }, icon: '/wp-content/themes/talkitout/assets/svg/pin.svg' },
        { title: 'Poole', position: { lat: 50.7151, lng: -1.9873 }, icon: '/wp-content/themes/talkitout/assets/svg/pin.svg' },
        { title: 'Christchurch', position: { lat: 50.7351, lng: -1.7784 }, icon: '/wp-content/themes/talkitout/assets/svg/pin.svg' }
    ];

    // Add markers to the map
    for (var i = 0; i < locations.length; i++) {
        var marker = new google.maps.Marker({
            position: locations[i].position,
            map: map,
            title: locations[i].title,
            icon: locations[i].icon
        });
    }
}

jQuery(document).ready(function($) {

    // Attach a click event handler to the element with class 'arrow'
    $('.arrow').click(function () {
        // Smoothly scroll down by 300 pixels
        $('html, body').animate({
            scrollTop: $(window).scrollTop() + 600
        }, 500); // Adjust the duration as needed
    });

    if($('#map').length){
        initMap();
    }

});
